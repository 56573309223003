/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from "react";

import useParentsProps from "@/hooks/useParentsProps";
import { CheckoutContext } from "@/providers/context/CheckoutContext";

export const useDefaultLang = (isStorybook = false) => {
    if (isStorybook) {
        return "en";
    }

    let defaultLang = "en";

    try {
        const { userSelectedLang: checkoutSelectedLang } =
            useContext(CheckoutContext);

        try {
            const { parentUser } = useParentsProps();
            defaultLang = parentUser
                ? parentUser.languageId
                : checkoutSelectedLang || "en";
        } catch (error) {
            defaultLang = checkoutSelectedLang || "en";
            console.debug(
                "useParentsProps not available, falling back to checkout context or default language"
            );
        }
    } catch (error) {
        console.debug(
            "Context providers not available, using default language"
        );
    }

    return defaultLang;
};
