import { extendTheme } from "@chakra-ui/react";
import type { GlobalStyleProps } from "@chakra-ui/theme-tools";

import Alert from "./components/Alert";
import Badge from "./components/Badge";
import Button from "./components/Button";
import Checkbox from "./components/Checkbox";
import Drawer from "./components/Drawer";
import FormLabel from "./components/FormLabel";
import Input from "./components/Input";
import Link from "./components/Link";
import Menu from "./components/Menu";
import Modal from "./components/Modal";
import Skeleton from "./components/Skeleton";
import Stepper from "./components/Stepper";
import Tabs from "./components/Tabs";
import Tag from "./components/Tag";
import Tooltip from "./components/Tooltip";
import layerStyles from "./layerStyles";

const theme = extendTheme({
    /**
     * Only use global styles to access breakpoints and provide media query rules
     */
    styles: {
        global: (props: GlobalStyleProps) => ({
            [`@media screen and (max-width: ${props.theme.breakpoints.sm})`]: {
                ".react-datepicker": {
                    width: "100%"
                }
            }
        })
    },
    fonts: {
        heading: "infini, sans-serif",
        body: "inter, sans-serif",
        secondary: "inter, sans-serif"
    },
    colors: {
        white: {
            500: "#ffffff"
        },
        burntYellow: {
            10: "#ffe788",
            50: "#fbf3e2",
            100: "#f8eacb",
            200: "#f5e1b5",
            300: "#f2d89e",
            400: "#efcf88",
            500: "#f0a32f",
            600: "#EA9B25",
            700: "#E2931D",
            800: "#a56225",
            900: "#8c4d21"
        },
        coral: {
            500: "#F7936F"
        },
        ebonyClay: {
            50: "#f7f7f8",
            100: "#e5e5e7",
            200: "#c3c3c7",
            300: "#a1a1a6",
            400: "#7f7f86",
            500: "#2b2c3c",
            600: "#282934",
            700: "#25252c",
            800: "#20232b",
            900: "#1c1e24"
        },
        mintJade: {
            50: "#f2f9f6",
            100: "#e6f3ed",
            200: "#c2e3d6",
            300: "#9ed3bf",
            400: "#7ac3a9",
            500: "#66cb9f",
            600: "#5dc598",
            700: "#4BBA89",
            800: "#3f8673",
            900: "#326d64"
        },
        slateGray: {
            500: "#425466",
            600: "#6b768b"
        },
        royalBlue: {
            100: "#F5F8FF",
            500: "#0066ff"
        },
        babyBlue: {
            25: "#F5FCFF",
            50: "#E1E8FF",
            100: "#00a4db",
            400: "#f5fcff",
            500: "#b4cae1"
        },
        mintGreen: {
            25: "#F3FFDF",
            50: "#DCF6B3",
            400: "#B6DE83",
            500: "#66cb9f",
            600: "#67a187"
        },
        sunsetRed: {
            50: "#ffede3",
            100: "#ffe6e4",
            300: "#f7c9ca",
            500: "#f16063"
        },
        oliveBlack: {
            500: "#2b2c3d"
        },
        coolMist: {
            50: "#f8fafd",
            100: "#f1f5fb",
            200: "#eaedf8",
            300: "#e3e8f4",
            400: "#dde4f0",
            500: "#d6e0ec",
            600: "#e2e8f1",
            700: "#b4c1d7",
            800: "#8b9caf",
            900: "#637391"
        },
        gray: {
            50: "#fafafa",
            100: "#f7fafc",
            200: "#edf2f7",
            300: "#e2e8f0",
            400: "#cbd5e0",
            500: "#a0aec0",
            600: "#718096",
            700: "#4a5568",
            800: "#2d3748",
            900: "#1a202c"
        },
        lightMist: {
            50: "#F9FAFB"
        },
        sunnyYellow: {
            100: "#fffdf5",
            500: "#FFE788",
            600: "#f7d44f"
        },
        lightBeige: {
            500: "#FEFCF3"
        },
        lightGray: {
            500: "#fbfbfe"
        },
        darkBlue: {
            500: "#00526e",
            600: "#336e82"
        },
        danger: {
            100: "#FEF2F3",
            500: "#F16063"
        },
        success: {
            100: "#DEFFEE",
            500: "#66CB9F",
            600: "#ADCF77"
        },
        warning: {
            50: "#FEFCF3",
            100: "#F7D44F",
            500: "#F7936F",
            600: "#ffe788"
        },
        duskBlue: {
            300: "#68DBF2",
            500: "#4CAFD0"
        },
        deepBlue: {
            500: "#00526E"
        },
        waxFlower: {
            50: "#fcba9e"
        }
    },
    breakpoints: {
        xxl: "96em", // ~1536px
        hd: "120em"
    },
    config: {
        cssVarPrefix: "euka"
    },
    layerStyles,
    components: {
        Alert,
        Badge,
        Button,
        Text,
        Checkbox,
        Input,
        FormLabel,
        Skeleton,
        Drawer,
        Tag,
        Modal,
        Stepper,
        Menu,
        Link,
        Tooltip,
        Tabs
    }
});

export default theme;
