import { createContext, useCallback, useEffect, useState } from "react";

import {
    ElectiveTypesInterface,
    NotEnrolStudentElectiveInterface
} from "@/ts/interfaces/Electives/ElectiveInterface";
import {
    DraftElectiveItemInterface,
    ElectiveRulesResponseApiInterface,
    LearningAreaApiInterface
} from "@/ts/interfaces/Electives/api/ElectivesApiInterface";

enum StateKey {
    USER_SELECTED_ELECTIVES = "userSelectedElectives",
    ENROLLED_STUDENT_ELECTIVES = "enrolledStudentElectives",
    NOT_ENROLLED_STUDENT_ELECTIVES = "notEnrolledStudentElectives",
    ELECTIVE_CONFIRMATION_SUCCESS = "electiveConfirmationSuccess"
}

interface PropTypes {
    children: React.ReactNode;
}

const persistState = (key: StateKey, value: string) => {
    sessionStorage.setItem(key, value);
};

const readState = (key: StateKey) => {
    return sessionStorage.getItem(key);
};

export interface ElectivesContextType {
    learningAreas: LearningAreaApiInterface[] | null;
    userSelectedLearningArea: number;
    userSelectedElectives: DraftElectiveItemInterface[];
    electiveRules: ElectiveRulesResponseApiInterface | null;
    electiveRulesError: string | null;
    draftElectives: DraftElectiveItemInterface[] | null;
    enrolledStudentElectives: ElectiveTypesInterface | null;
    isSyncServerDraftElectives: boolean;
    notEnrolledStudentElectives: NotEnrolStudentElectiveInterface | null;
    confirmElectivesResponseMessage: string | null;

    onUpdateLearningAreas: (learningAreas: LearningAreaApiInterface[]) => void;
    onUpdateUserSelectedLearningArea: (id: number) => void;
    onAddSelectedElectives: (elective: DraftElectiveItemInterface) => void;
    onRemoveSelectedElectives: (elective: DraftElectiveItemInterface) => void;
    onUpdateEnrolledStudentElectives: (data: ElectiveTypesInterface) => void;
    onUpdateIsSyncServerDraftElectives: (isSyncing: boolean) => void;
    removeSessionStoredSelectedElectives: () => void;
    onUpdateDraftElectives: (
        draftElectives: DraftElectiveItemInterface[]
    ) => void;
    onUpdateNotEnrolledStudentElectives: (
        data: NotEnrolStudentElectiveInterface
    ) => void;
    onUpdateElectiveRules: (rules: ElectiveRulesResponseApiInterface) => void;
    onUpdateConfirmElectivesResponseMessage: (message: string) => void;

    clearElectivesContext: () => void;
}

export const ElectivesContext = createContext<ElectivesContextType>({
    learningAreas: null,
    userSelectedLearningArea: 0,
    userSelectedElectives: [],
    electiveRules: null,
    electiveRulesError: null,
    draftElectives: null,
    enrolledStudentElectives: null,
    isSyncServerDraftElectives: false,
    notEnrolledStudentElectives: null,
    confirmElectivesResponseMessage: null,

    onUpdateLearningAreas: () => {},
    onUpdateUserSelectedLearningArea: () => {},
    onAddSelectedElectives: () => {},
    onRemoveSelectedElectives: () => {},
    onUpdateEnrolledStudentElectives: () => {},
    onUpdateIsSyncServerDraftElectives: () => {},
    removeSessionStoredSelectedElectives: () => {},
    onUpdateDraftElectives: () => {},
    onUpdateNotEnrolledStudentElectives: () => {},
    onUpdateElectiveRules: () => {},
    onUpdateConfirmElectivesResponseMessage: () => {},

    clearElectivesContext: () => {}
});

const ElectivesContextProvider = ({ children }: PropTypes) => {
    const [learningAreas, setLearningAreas] = useState<
        LearningAreaApiInterface[]
    >([]);
    const [userSelectedLearningArea, setUserSelectedLearningArea] = useState(0);
    const [isSyncServerDraftElectives, setIsSyncServerDraftElectives] =
        useState(false);
    const [userSelectedElectives, setUserSelectedElectives] = useState<
        DraftElectiveItemInterface[]
    >(() => {
        const storedElectives = readState(StateKey.USER_SELECTED_ELECTIVES);
        return storedElectives ? JSON.parse(storedElectives) : [];
    });
    const [enrolledStudentElectives, setEnrolledStudentElectives] =
        useState<ElectiveTypesInterface | null>(null);
    const [electiveRules, setElectiveRules] =
        useState<ElectiveRulesResponseApiInterface | null>(null);
    const [electiveRulesError, setElectiveRulesError] = useState<string | null>(
        null
    );
    const [notEnrolledStudentElectives, setNotEnrolledStudentElectives] =
        useState<NotEnrolStudentElectiveInterface | null>(null);
    const [
        confirmElectivesResponseMessage,
        setConfirmElectivesResponseMessage
    ] = useState<string | null>(() => {
        const storedConfirmation = readState(
            StateKey.ELECTIVE_CONFIRMATION_SUCCESS
        );
        return storedConfirmation ? JSON.parse(storedConfirmation) : null;
    });

    useEffect(() => {
        const savedElectives = readState(StateKey.USER_SELECTED_ELECTIVES);
        const parsedSavedElectives = savedElectives
            ? JSON.parse(savedElectives)
            : [];
        const hasSavedElectives = parsedSavedElectives.length > 0;

        if (hasSavedElectives) {
            setUserSelectedElectives(parsedSavedElectives);
        } else if (userSelectedElectives.length === 0) {
            setIsSyncServerDraftElectives(true);
        }
    }, []);

    const onUpdateLearningAreas = useCallback(
        (learningAreas: LearningAreaApiInterface[]) => {
            setLearningAreas(learningAreas);
        },
        []
    );

    const onUpdateUserSelectedLearningArea = useCallback((id: number) => {
        setUserSelectedLearningArea(id);
    }, []);

    const onAddSelectedElectives = useCallback(
        (
            electivesToAdd:
                | DraftElectiveItemInterface
                | DraftElectiveItemInterface[]
        ) => {
            setUserSelectedElectives(prevElectives => {
                const electivesArray = Array.isArray(electivesToAdd)
                    ? electivesToAdd
                    : [electivesToAdd];
                const newElectives = electivesArray.filter(
                    elective => !prevElectives.some(e => e.id === elective.id)
                );
                const updatedElectives = [...prevElectives, ...newElectives];
                persistState(
                    StateKey.USER_SELECTED_ELECTIVES,
                    JSON.stringify(updatedElectives)
                );
                return updatedElectives;
            });
        },
        []
    );

    const onUpdateDraftElectives = useCallback(
        (draftElectives: DraftElectiveItemInterface[]) => {
            setUserSelectedElectives(draftElectives);
            persistState(
                StateKey.USER_SELECTED_ELECTIVES,
                JSON.stringify(draftElectives)
            );
        },
        []
    );

    const removeSessionStoredSelectedElectives = useCallback(() => {
        sessionStorage.removeItem(StateKey.USER_SELECTED_ELECTIVES);
    }, []);

    const onRemoveSelectedElectives = useCallback(
        (elective: DraftElectiveItemInterface) => {
            const updatedElectives = userSelectedElectives.filter(
                selectedElective => selectedElective.id !== elective.id
            );
            setUserSelectedElectives(updatedElectives);
            persistState(
                StateKey.USER_SELECTED_ELECTIVES,
                JSON.stringify(updatedElectives)
            );
        },
        [userSelectedElectives]
    );

    const onUpdateEnrolledStudentElectives = useCallback(
        (data: ElectiveTypesInterface) => {
            setEnrolledStudentElectives(data);
            sessionStorage.setItem(
                StateKey.ENROLLED_STUDENT_ELECTIVES,
                JSON.stringify(data)
            );
        },
        []
    );

    const onUpdateIsSyncServerDraftElectives = useCallback(
        (isSyncing: boolean) => {
            setIsSyncServerDraftElectives(isSyncing);
        },
        []
    );

    const onUpdateElectiveRules = useCallback(
        (rules: ElectiveRulesResponseApiInterface) => {
            setElectiveRules(rules);
        },
        []
    );

    const onUpdateNotEnrolledStudentElectives = useCallback(
        (data: NotEnrolStudentElectiveInterface) => {
            setNotEnrolledStudentElectives(data);
            sessionStorage.setItem(
                StateKey.NOT_ENROLLED_STUDENT_ELECTIVES,
                JSON.stringify(data)
            );
        },
        []
    );

    const onUpdateConfirmElectivesResponseMessage = useCallback(
        (message: string) => {
            setConfirmElectivesResponseMessage(message);
            sessionStorage.setItem(
                StateKey.ELECTIVE_CONFIRMATION_SUCCESS,
                JSON.stringify(message)
            );
        },
        []
    );

    const clearElectivesContext = useCallback(() => {
        setLearningAreas([]);
        setUserSelectedLearningArea(0);
        setEnrolledStudentElectives(null);
        sessionStorage.removeItem(StateKey.ENROLLED_STUDENT_ELECTIVES);
        setIsSyncServerDraftElectives(false);
        setUserSelectedElectives([]);
        setElectiveRules(null);
        setElectiveRulesError(null);
        sessionStorage.removeItem(StateKey.NOT_ENROLLED_STUDENT_ELECTIVES);
        setNotEnrolledStudentElectives(null);
        sessionStorage.removeItem(StateKey.ELECTIVE_CONFIRMATION_SUCCESS);
        setConfirmElectivesResponseMessage(null);
    }, []);

    return (
        <ElectivesContext.Provider
            value={{
                learningAreas,
                userSelectedLearningArea,
                userSelectedElectives,
                electiveRules,
                electiveRulesError,
                draftElectives: [],
                enrolledStudentElectives,
                isSyncServerDraftElectives,
                notEnrolledStudentElectives,
                confirmElectivesResponseMessage,

                onUpdateLearningAreas,
                onUpdateUserSelectedLearningArea,
                onAddSelectedElectives,
                onRemoveSelectedElectives,
                onUpdateEnrolledStudentElectives,
                onUpdateIsSyncServerDraftElectives,
                removeSessionStoredSelectedElectives,
                onUpdateDraftElectives,
                onUpdateElectiveRules,
                onUpdateNotEnrolledStudentElectives,
                onUpdateConfirmElectivesResponseMessage,
                clearElectivesContext
            }}
        >
            {children}
        </ElectivesContext.Provider>
    );
};

export default ElectivesContextProvider;
